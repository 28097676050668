import Image from "next/image";

const ProductGalleryListComponent = ({ items }) => {
  if (items === undefined) {
    return <></>;
  } else {
    return (
      <div className='row g-4'>
        {items.length > 0 &&
          items.map((item, idx) => {
            const img = item.image.length > 0 ? item.image[0].url : "";
            const width =
              item.image.length > 0 ? item.image[0].metadata.pixelWidth : 300;
            const height =
              item.image.length > 0 ? item.image[0].metadata.pixelHeight : 300;
            return (
              <div key={idx} className='col-lg-6'>
                <div className='bg-light border'>
                  <h2 className='text-center text-uppercase fw-bold h3 p-2'>
                    {item.title}
                  </h2>
                  <div className='bg-white py-3 text-center'>
                    <Image
                      src={img}
                      width={width}
                      height={height}
                      alt=''
                      className='img-fluid mx-auto d-block'
                      layout='raw'
                    ></Image>
                  </div>
                  <p className='p-3 text-center'>{item.description}</p>
                </div>
              </div>
            );
          })}
      </div>
    );
  }
};

export default ProductGalleryListComponent;
