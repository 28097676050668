import Image from "next/image";
import React from "react";

const BulletinImageTileListComponent = (props) => {
  const data = props;

  return (
    <>
      <div className='container p-2'>
        <div className='row'>
          <div className='col-12 mt-4'>
            <h3 className='fw-bold'>{data?.title}</h3>
            <div
              className='card-body'
              dangerouslySetInnerHTML={{ __html: data?.description }}
            ></div>
          </div>
          <div className='col-12'>
            <div className='row'>
              {data?.images.length > 0 &&
                data?.images.map((item, index) => (
                  <React.Fragment key={index}>
                    <div className='col-4 m-4 mx-auto'>
                      <Image
                        src={item?.image[0]?.url}
                        alt={data?.title}
                        width={400}
                        height={300}
                      />
                      <figure className='text-center'>
                        {item?.imageDescription}
                      </figure>
                    </div>
                  </React.Fragment>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BulletinImageTileListComponent;
