/* eslint-disable @next/next/no-img-element */
import { useRouter } from "next/router";
import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import SectionsRender from "./SectionsRender";

const TabsComponent = ({ tabs, urlPath }) => {
  const router = useRouter();
  if (tabs === undefined || tabs === null) return <></>;
  if (tabs.length === 0) return <></>;

  let defaultKey = "";
  let defaultTab = tabs.find((x) => x.isDefaultTab == true);
  if (defaultTab !== undefined) {
    defaultKey = defaultTab.urlId;
  }

  let lastUrl = null;
  if (urlPath && Array.isArray(urlPath)) {
    if (urlPath.length > 1) {
      lastUrl = urlPath[1];
      defaultTab = tabs.find((x) => x.urlId == lastUrl);
      if (defaultTab) {
        defaultKey = defaultTab.urlId;
      }
    }
  }

  const onSelect = (key) => {
    //console.log("tab selected", key);
    const firstUrl = urlPath[0];
    router.push(`/${firstUrl}/${key}`, undefined, { shallow: true });
  };

  return (
    <Tabs
      className='nav nav-tabs justify-content-center custom-tabs'
      defaultActiveKey={defaultKey}
      onSelect={onSelect}
    >
      {tabs.length > 0 &&
        tabs.map((tab, idx) => {
          if (tab.tabPageId && tab.tabPageId.length > 0) {
            const data = tab.tabPageId[0].flatData;
            //console.log("tab render", data);
            return (
              <Tab eventKey={tab.urlId} title={tab.title} key={idx}>
                <div className='my-5'>
                  <SectionsRender {...data}></SectionsRender>
                </div>
              </Tab>
            );
          } else {
            return <></>;
          }
        })}
    </Tabs>
  );
};

export default TabsComponent;
