import Image from "next/image";
import React from "react";
import { Col, Row } from "react-bootstrap";
import styles from "./SupportTeamComponent.module.scss";

const SupportTeamComponent = ({ heading, subHeading, persons }) => {
  const people = persons;
  return (
    <div>
      <h3 className='fw-bolder'>{heading}</h3>
      <p className='mb-5 '>{subHeading}</p>
      <div>
        <Row
          xs={2}
          sm={2}
          md={3}
          lg={4}
          xl={5}
          xxl={6}
          className={styles.personContainer}
        >
          {people.map((person, index) => (
            <Col key={index}>
              <Image
                src={person?.image[0]?.url}
                alt={person.name}
                width={180}
                height={180}
                layout='responsive'
                className='rounded-circle'
              />

              <h4 className='fw-bolder text-center mb-0 mt-3'>{person.name}</h4>
              {person?.jobPosition.split("\n").map((line, lineIndex) => (
                <div
                  className='text-center'
                  key={lineIndex}
                  dangerouslySetInnerHTML={{ __html: line }}
                />
              ))}
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default SupportTeamComponent;
