import FsLightbox from "fslightbox-react";
import React, { useState } from "react";

const CallToActionComponent = ({ title, linkType, download, url }) => {
  const [toggler, setToggler] = useState(false);

  const downloadButton = linkType === "Download";
  const href = url !== null ? url : download ? download[0].url : "";

  if (downloadButton) {
    return (
      <div className='py-2'>
        <a
          className='btn btn-primary'
          href={href}
          target='_blank'
          rel='noreferrer'
          onClick={() => setToggler(!toggler)}
        >
          {title}
        </a>
      </div>
    );
  }
  return (
    <div>
      <a className='btn btn-primary' onClick={() => setToggler(!toggler)}>
        {title}
      </a>
      <FsLightbox toggler={toggler} sources={[href]} />
    </div>
  );
};

export default CallToActionComponent;
