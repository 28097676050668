/* eslint-disable @next/next/no-img-element */
import React from "react";

const BenefitTextTilesComponent = ({ title, items }) => {
  return (
    <div className='product-catalog-section py-3 py-md-5'>
      <h4 className='d-flex py-0 align-items-center gap-2'>
        <span>{title}</span>
      </h4>
      <div className='row'>
        {items.length > 0 &&
          items.map((item, idx) => {
            return (
              <div className='col-sm-3 mb-2' key={idx}>
                <div className='w-100 resource-card'>
                  <div className='dark-teal p-3'>
                    <h3>{item.title}</h3>
                    <p>{item.subTitle}</p>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default BenefitTextTilesComponent;
