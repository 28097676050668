import RenderComponents from "./RenderFactory";

const SectionsRender = ({ sections: sections, __pageIdArray }) => {
  if (sections === undefined || typeof sections.length ==='undefined') {
    return <></>;

  } 
  else if (sections.length > 0) {
    return (
      <>
        {sections.map(function (section, idx) {
          const fullWidth =
            section.fullWidth !== null ? section.fullWidth : false;
          let paddingY = "pb-5";
          if (sections?.length === idx + 1) {
            paddingY = "";
          }
          if (
            section.details.__typename === "VideoComponent" ||
            section.details.__typename === "RoundelListComponent"
          ) {
            paddingY = "";
            
            return (
              <div key={idx} className={`${paddingY}`}>
                <RenderComponents
                  data={section.details}
                  urlPath={__pageIdArray}
                />
              </div>
            );
          }
          // console.log("section_details", section.details)
          return (
            <div key={idx} className={`${!fullWidth ? "container" : ""}`}>
              <div className={`${fullWidth ? "row-fluid" : "row"} ${paddingY}`}>
                <div className='col-12'>
                  <RenderComponents
                    data={section.details}
                    urlPath={__pageIdArray}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  }
  else{
    return <></>;
  }
};

export default SectionsRender;
