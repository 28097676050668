import Vimeo from "./Vimeo";

const VideoComponent = ({
  videoId,
  videoType,
  coverImage,
  prefixText,
  suffixText,
  backgroundColor,
}) => {
  if (videoId === undefined) {
    return <></>;
  } else if (videoType === "vimeo") {
    let bgColor = "bg-light border";
    if (backgroundColor) {
      if (backgroundColor === "highlight") {
        bgColor = "bg-secondary";
      }
    }
    return (
      <div className={bgColor}>
        {prefixText && (
          <div className='container pt-4 pb-3'>
            <div dangerouslySetInnerHTML={{ __html: prefixText }}></div>
          </div>
        )}
        <div className='new-space'>
          <div className='video-display w-100'>
            <Vimeo video={videoId} backgroundImage={coverImage[0]?.url}></Vimeo>
          </div>
        </div>
        {suffixText && (
          <div className='container py-3'>
            <div dangerouslySetInnerHTML={{ __html: suffixText }}></div>
          </div>
        )}
      </div>
    );
  } else {
    return <></>;
  }
};

export default VideoComponent;
