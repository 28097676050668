import Link from "next/link";
import React from "react";

const CoolantFilterTableComponent = ({ rows = [] }) => {
  return (
    <div className='mb-4'>
      <div className='table-responsive'>
        <table className='table-bordered table'>
          <thead>
            <tr className='bg-primary text-white'>
              <th>
                <strong>Ryco No.</strong>
              </th>
              <th>
                <strong>Description</strong>
              </th>
              <th colSpan='5'>
                <strong>Interchangeable with</strong>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className='bg-secondary text-white'>
              <th>&nbsp;</th>
              <th>&nbsp;</th>
              <th>OE</th>
              <th>OE Number</th>
              <th>Fleetguard</th>
              <th>Donaldson</th>
              <th>Baldwin</th>
            </tr>
            {rows.length > 0 &&
              rows.map((item, idx) => {
                return (
                  <tr key={idx}>
                    <td>
                      <Link href={`/part/${item.partNo}`} legacyBehavior>
                        <a>{item.partNo}</a>
                      </Link>
                    </td>
                    <td>{item.description}</td>
                    <td>{item.oe}</td>
                    <td>{item.oeNumber}</td>
                    <td>{item.fleetguard}</td>
                    <td>{item.donaldson}</td>
                    <td>{item.baldwin}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CoolantFilterTableComponent;
