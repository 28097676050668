import Image from "next/image";

const ImageTopTextComponent = ({ data, image }) => {
  if (image === undefined) {
    return <></>;
  } else {
    const img = image.length > 0 ? image[0].url : "";
    return (
      <>
        <div className='card d-lg-flex d-block flex-row bg-light align-items-center border-0 mb-md-3 md-0 mb-lg-5'>
          <div className='row'>
            <div className='col-lg-12'>
              <Image
                src={img}
                width='1500'
                height='500'
                className='w-100 img-fluid h-auto'
                alt='banner'
                layout="raw"
              />
            </div>
            <div className='col-lg-12'>
              <div
                className='card-body'
                dangerouslySetInnerHTML={{ __html: data }}
              ></div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default ImageTopTextComponent;
