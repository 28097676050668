/* eslint-disable @next/next/no-img-element */
import { useRouter } from "next/router";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const showAlert = (error) => {
  MySwal.fire({
    title: "Error Occured!",
    text: error,
    icon: "error",
    timerProgressBar: false,
    showConfirmButton: false,
    showCancelButton: true,
    cancelButtonText: "Close",
  });
};

const MakeToProductListComponent = ({ title, subTitle, items }) => {
  const initValue = items.length > 0 ? items[0].productCode : "";
  const [currentMake, setMake] = useState(initValue);
  const [isBusy, setIsBusy] = useState(false);

  const router = useRouter();
  const changeMake = (productCode) => {
    setMake(productCode);
  };

  const handleClick = (e) => {
    e.preventDefault();
    setIsBusy(true);
    let href = currentMake;
    if (href != "") {
      router.push(href);
    } else {
      showAlert("Please select a Make");
    }
  };
  
  return (
    <div className='row g-0 mb-2'>
      <div className='col-lg-12 bg-secondary text-white p-md-5 p-3 search-form'>
        <h3 className='h4'>{title}</h3>
        <small>{subTitle}</small>
        <div className='row g-2 mt-2'>
          <small>MAKE</small>
          <div className='col-lg-9'>
            <select
              className='form-select'
              required=''
              onChange={(event) => changeMake(event.target.value)}
            >
              {items &&
                items.map((item, idx) => {
                  return (
                    <option value={item.productCode} key={idx}>
                      {item.make}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className='col-lg-3'>
            <button
              type='submit'
              className='btn btn-primary mb-2 w-100'
              onClick={handleClick}
            >
              {isBusy && (
                <>
                  <Spinner
                    as='span'
                    animation='border'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                </>
              )}
              {!isBusy && <>Go To Product</>}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MakeToProductListComponent;
