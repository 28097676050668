/* eslint-disable @next/next/no-img-element */
import Image from "next/image";

const ImageWithOverlayImageComponent = ({ image, overlayImage }) => {
  if (image === undefined) {
    return <></>;
  } else {
    const img = image.length > 0 ? image[0].url : "";
    const ovImg = overlayImage.length > 0 ? overlayImage[0].url : "";
    return (
      <>
        <div className='aboutusimg'>
          <Image
            src={img}
            width='775'
            height='550'
            alt='aboutus'
            className='img-fluid'
            layout='raw'
          />
          <img
            src={ovImg}
            alt='headline'
            width='200'
            height='200'
            className='aboutusimgheadline'
            loading='lazy'
          />
          <div className='gradientoverlay'></div>
        </div>
      </>
    );
  }
};

export default ImageWithOverlayImageComponent;
