/* eslint-disable @next/next/no-img-element */
import Link from "next/link";
import React from "react";

const ImageCol = ({ image, title }) => {
  try {
    const img = image[0].url;
    return (
      <div className='col-lg-6 col-sm-12'>
        <img
          src={img}
          className='img-fluid p-4'
          alt={title}
          width={257}
          height={178}
        />
      </div>
    );
  } catch (error) {
    return <></>;
  }
};

const TextCol = ({ title, subTitle, description, titleLink = "" }) => {
  const showLink = (titleLink ?? "") !== "";
  return (
    <div className='col-lg-6 col-sm-12'>
      <h3 className='card-md-title'>
        {showLink && (
          <Link href={titleLink} legacyBehavior>
            <a>{title}</a>
          </Link>
        )}
        {!showLink && <span>{title}</span>}
      </h3>
      <h4>{subTitle}</h4>
      <p>{description}</p>
    </div>
  );
};

const TwoColProductComponent = ({ items }) => {
  //console.log("left col", leftCol);

  return (
    <div className='row g-2 pb-2'>
      {items &&
        items.map((item, idx) => {
          const { image, imageLocation } = item;
          const isLeft = imageLocation === "left";
          return (
            <div key={idx} className='col-lg-6'>
              <div className='p-3 border bg-light d-lg-flex d-height'>
                {isLeft && <ImageCol {...item}></ImageCol>}
                <TextCol {...item}></TextCol>
                {!isLeft && <ImageCol {...item}></ImageCol>}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default TwoColProductComponent;
