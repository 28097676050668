import React from "react";

const ParaSectionsWithTocComponent = ({ items }) => {
  if (items === undefined) {
    return <></>;
  } else if (items.length > 0) {
    return (
      <>
        <div className='col-12'>
          <h3 id='rg' className='margin-bottom-10'>
            Quick Links
          </h3>
          <div className='pl-4 py-3'>
            <ul className='anchor-menu-links'>
              {items.map(function (item, idx) {
                return (
                  <li key={idx}>
                    <a href={"#item-" + idx}>{item.title}</a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        {items.map(function (item, idx) {
          return (
            <div className='col-12' key={idx}>
              <div className='component-area'>
                <h4 className='component-header' id={"item-" + idx}>
                  {item.title}
                </h4>
                <div dangerouslySetInnerHTML={{ __html: item.body }}></div>
              </div>
            </div>
          );
        })}
      </>
    );
  } else {
    return <></>;
  }
};

export default ParaSectionsWithTocComponent;
