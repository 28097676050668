import React from "react";
import RenderComponents from "./RenderFactory";

const TwoColSectionComponent = ({ leftCol, rightCol }) => {
  //console.log("TwoColSectionComponent", leftCol, rightCol);

  return (
    <>
      <div className='row'>
        <div className='col-md-6'>
          <RenderComponents data={leftCol}></RenderComponents>
        </div>
        <div className='col-md-6'>
          <RenderComponents data={rightCol}></RenderComponents>
        </div>
      </div>
    </>
  );
};

export default TwoColSectionComponent;
