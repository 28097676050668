/* eslint-disable @next/next/no-img-element */
import React from "react";

const ImageCol = ({ image, title }) => {
  try {
    const img = image[0].url;
    return (
      <div className='col-5'>
        <img
          src={img}
          className='img-fluid'
          alt={title}
          width={257}
          height={178}
        />
      </div>
    );
  } catch (error) {
    return <></>;
  }
};
const TextCol = ({ title, subTitle, description }) => {
  return (
    <div className='col-7 pt-5'>
      <h3 className='card-md-title'>{title}</h3>
      <h4>{subTitle}</h4>
      {description && <p>{description}</p>}
    </div>
  );
};

const RoundelListComponent = ({ items }) => {
  return (
    <div className='container-fluid bg-light  pb-4'>
      <div className='container'>
        <div className='row g-2 pb-5'>
          {items &&
            items.map((item, idx) => {
              const { image, imageLocation } = item;
              const isLeft = imageLocation === "left";
              return (
                <div key={idx} className='col-lg-6'>
                  <div className='px-3 d-flex'>
                    {isLeft && <ImageCol {...item}></ImageCol>}
                    <TextCol {...item}></TextCol>
                    {!isLeft && <ImageCol {...item}></ImageCol>}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default RoundelListComponent;
