/* eslint-disable @next/next/no-img-element */
import Image from "next/image";
import useWindowSize from "../../utils/useWindowSize";
import styles from "./ImageBlockComponent.module.scss";
const ImageBlockComponent = ({ caption, image, imageMobile }) => {
  let size = useWindowSize();
  if (image === undefined) {
    return <></>;
  } else {
    const img = image.length > 0 ? image[0].url : "";
    const imgMobileLocal = imageMobile ? imageMobile[0].url : null;
    const imgCss = `url(${img}); height:385px`;
    const imgMobileCss = `url(${imgMobileLocal}); height:100%; color: black`;
    const showCaption = caption ? true : false;

    return (
      <>
        {imgMobileLocal && (
          <>
            <div className='container'>
              {size.width < 768 ? (
                <Image
                  src={imgMobileLocal}
                  alt='Image description'
                  width={516}
                  height={444}
                  sizes='(max-width: 768px) 100vw, 516px'
                  className='w-100'
                  loading='lazy'
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              ) : (
                <Image
                  src={img}
                  alt='image'
                  width={1296}
                  height={371}
                  className='img-fluid '
                  layout='raw'
                  loading='lazy'
                />
              )}
            </div>
          </>
        )}
        {!imgMobileLocal && (
          <>
            <div
              className={`${styles.bannerSection} w-100`}
              style={{ backgroundImage: imgCss }}
            >
              {showCaption && (
                <div className={styles.polygonWt}>
                  <div className='container p-2'>
                    <div className='pt-4'>
                      <h2 className='h2 text-white fw-500'>{caption}</h2>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </>
    );
  }
};

export default ImageBlockComponent;
