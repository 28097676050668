import Image from "next/image";
import CallToActionComponent from "./CallToActionComponent";

const TwoColImageTextComponent = ({
  body,
  imageLocation,
  image,
  callToAction,
  backgroundColor,
}) => {
  const showCallToAction = callToAction !== undefined && callToAction !== null;
  const bgColor =
    backgroundColor != null
      ? backgroundColor == "light gray"
        ? "bg-light"
        : ""
      : "";
  let img = null;
  if (image) {
    if (image.length > 0) {
      img = image[0].url;
    }
  }
  if (image === undefined) {
    return <></>;
  } else if (imageLocation === "Right") {
    return (
      <div
        className={`card d-lg-flex d-block flex-row align-items-center border-0 mb-md-3 md-0 ${bgColor}`}
      >
        <div className='col-lg-6'>
          <div className='card-body'>
            <div dangerouslySetInnerHTML={{ __html: body }}></div>
            {showCallToAction && (
              <CallToActionComponent {...callToAction}></CallToActionComponent>
            )}
          </div>
        </div>
        <div className='col-lg-6'>
          {img && (
            <Image
              src={img}
              width='660'
              height='543'
              layout='raw'
              className='w-100 img-fluid h-auto'
              alt='imge description'
            ></Image>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={`card d-lg-flex d-block flex-row align-items-center border-0 mb-md-3 md-0 ${bgColor}`}
      >
        <div className='col-lg-6'>
          {img && (
            <Image
              src={img}
              width='660'
              height='543'
              layout='raw'
              className='w-100 img-fluid h-auto'
              alt='imge description'
            ></Image>
          )}
        </div>
        <div className='col-lg-6'>
          <div className='card-body'>
            <div dangerouslySetInnerHTML={{ __html: body }}></div>
            {showCallToAction && (
              <CallToActionComponent {...callToAction}></CallToActionComponent>
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default TwoColImageTextComponent;
