import dynamic from "next/dynamic";
import FindPartsSection from "../Home/FindPartsSection";
import BenefitTextTilesComponent from "./BenefitTextTilesComponent";
import BulletinImageTileListComponent from "./BulletinImageTileListComponent";
import CoolantFilterTableComponent from "./CoolantFilterTableComponent";
import FeaturedPartsComponent from "./FeaturedPartsComponent";
import ImageBlockComponent from "./ImageBlockComponent";
import ImageTopTextComponent from "./ImageTopTextComponent";
import ImageWithOverlayImageComponent from "./ImageWithOverlayImageComponent";
import MakeToProductListComponent from "./MakeToProductListComponent";
import ParaSectionsWithTocComponent from "./ParaSectionsWithTocComponent";
import PcvTableComponent from "./PcvTableComponent";
import ProductGalleryListComponent from "./ProductGalleryListComponent";
import ProductGalleryWithLinkComponent from "./ProductGalleryWithLinkComponent";
import RichtextSectionComponent from "./RichtextSectionComponent";
import RoundelListComponent from "./RoundelListComponent";
import TabsComponent from "./TabsComponent";
import TextTilesComponent from "./TextTilesComponent";
import TileListComponent from "./TileListComponent ";
import TowColTableComponent from "./TowColTableComponent";
import TwoColImageTextComponent from "./TwoColImageTextComponent";
import TwoColProductComponent from "./TwoColProductComponent";
import TwoColSectionComponent from "./TwoColSectionComponent";
import VideoComponent from "./VideoComponent";
import BuynowBlockComponent from "./BuynowBlockComponent";
import PageImageBlockComponent from "./PageImageBlockComponent";
import SupportTeamComponent from "./SupportTeamComponent";

const DynGallerySliderComponent = dynamic(
  () => import("./GallerySliderComponent"),
  { ssr: false }
);
const DynProductSliderComponent = dynamic(
  () => import("./ProductSliderComponent"),
  { ssr: false }
);
const DynProductsByCategory = dynamic(
  () => import("../Home/ProductsByCategory"),
  { ssr: false }
);

const DynProductsByApplication = dynamic(
  () => import("../Home/ProductsByApplication"),
  { ssr: false }
);

const RenderComponents = ({ data, urlPath }) => {
  // console.log("Render--Components", data.__typename);
  let typeName = "";
  if (data && data.__typename) {
    typeName = data.__typename;
  }
  // console.log("type_", typeName);
  switch (typeName) {
    case "ProductCategorySliderTabsComponent":
      return <DynProductsByCategory data={data.tabs}></DynProductsByCategory>;
    case "FindPartsSectionComponent":
      return <FindPartsSection {...data}></FindPartsSection>;
    case "TwoColSectionComponent":
      return <TwoColSectionComponent {...data}></TwoColSectionComponent>;
    case "RichtextSectionComponent":
      return <RichtextSectionComponent {...data}></RichtextSectionComponent>;
    case "ParaSectionsWithTocComponent":
      return (
        <ParaSectionsWithTocComponent {...data}></ParaSectionsWithTocComponent>
      );
    case "ImageBlockComponent":
      return <ImageBlockComponent {...data}></ImageBlockComponent>;
    case "PageImageBlockComponent":
      return <PageImageBlockComponent {...data}></PageImageBlockComponent>;
    case "TileListComponent":
      return <TileListComponent {...data}></TileListComponent>;
    case "TwoColImageTextComponent":
      return <TwoColImageTextComponent {...data}></TwoColImageTextComponent>;
    case "GallerySliderComponent":
      return <DynGallerySliderComponent {...data}></DynGallerySliderComponent>;
    case "ProductSliderComponent":
      return <DynProductSliderComponent {...data}></DynProductSliderComponent>;
    case "VideoComponent":
      return <VideoComponent {...data}></VideoComponent>;
    case "ProductsByApplicationComponent":
      return <DynProductsByApplication {...data}></DynProductsByApplication>;
    case "ImageWithOverlayImageComponent":
      return (
        <ImageWithOverlayImageComponent
          {...data}
        ></ImageWithOverlayImageComponent>
      );
    case "TextTilesComponent":
      return <TextTilesComponent {...data}></TextTilesComponent>;
    case "ImageTopTextComponent":
      return <ImageTopTextComponent {...data}></ImageTopTextComponent>;
    case "BenefitTextTilesComponent":
      return <BenefitTextTilesComponent {...data}></BenefitTextTilesComponent>;
    case "TwoColProductComponent":
      return <TwoColProductComponent {...data}></TwoColProductComponent>;
    case "RoundelListComponent":
      return <RoundelListComponent {...data}></RoundelListComponent>;
    case "CoolantFilterTableComponent":
      return (
        <CoolantFilterTableComponent {...data}></CoolantFilterTableComponent>
      );
    case "MakeToProductListComponent":
      return (
        <MakeToProductListComponent {...data}></MakeToProductListComponent>
      );
    case "ProductGalleryListComponent":
      return (
        <ProductGalleryListComponent {...data}></ProductGalleryListComponent>
      );
    case "TabsComponent":
      return <TabsComponent {...data} urlPath={urlPath}></TabsComponent>;
    case "ProductGalleryWithLinkComponent":
      return (
        <ProductGalleryWithLinkComponent
          {...data}
        ></ProductGalleryWithLinkComponent>
      );
    case "FeaturedPartsComponent":
      return <FeaturedPartsComponent {...data}></FeaturedPartsComponent>;
    case "PcvTableComponent":
      return <PcvTableComponent {...data}></PcvTableComponent>;
    case "BuynowBlockComponent":
      return <BuynowBlockComponent {...data}></BuynowBlockComponent>;
    case "BulletinImageTileListComponent":
      return <BulletinImageTileListComponent {...data} />;
    case "TowColTableComponent":
      return <TowColTableComponent {...data} />;
      case "SupportTeamComponent":
        return <SupportTeamComponent {...data} />;
    default:
      return <div>___| Component Not Designed: {typeName} |___</div>;
  }
};

export default RenderComponents;
