import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Image from "next/image";

const BuynowBlockComponent = ({ stockcode }) => {
  
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    
    const isAU = process.env.NEXT_PUBLIC_SITE_IS_AU == "true";
    const pid = isAU ? "12047922" : "12047923";    
    const url = `https://where-to-buy.co/widgets/core/BuyOnlineBuyLocalV2/index.html?pid=${pid}&model=${stockcode}`;


  return (
    <div>
      <button className="btn btn-primary" onClick={handleShow}>BUY NOW</button>
      <Modal show={showModal} onHide={handleClose} size="lg" className="my-com-modal">
      <Modal.Header className="my-com-modal-header">
      <a href="#" onClick={handleClose}>
                        <Image
                            src="/icons/close.svg"
                            width='32'
                            height='32'
                            className='svg-filter-white'
                            alt='Close'
                            layout='raw'
                        />
                        </a>
        </Modal.Header>
        <Modal.Body className="my-com-modal-body">
            <div className="ca-com-container-wrapper">
            <div className="ca-com-container">
                <iframe src={url} frameBorder='0' scrolling='no' style={{'border': 'none','width':'100%'}}></iframe>
                {/* <div style={{'background-color':'green','width':'100%','height':'80vh'}}>hkjh</div> */}
            </div>
            </div>
        </Modal.Body>        
      </Modal>
    </div>
  );
};

export default BuynowBlockComponent;
