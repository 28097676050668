const eventNames = {
  play: "onPlay",
  pause: "onPause",
  ended: "onEnd",
  timeupdate: "onTimeUpdate",
  progress: "onProgress",
  seeked: "onSeeked",
  texttrackchange: "onTextTrackChange",
  cuechange: "onCueChange",
  cuepoint: "onCuePoint",
  volumechange: "onVolumeChange",
  playbackratechange: "onPlaybackRateChange",
  error: "onError",
  loaded: "onLoaded",
};

export default eventNames;
