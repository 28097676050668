import React from "react";

const RichtextSectionComponent = ({ data, bgColor = "default" }) => {
  if (bgColor == "gray") {
    return (
      <div className='bg-light py-4'>
        <div dangerouslySetInnerHTML={{ __html: data }}></div>
      </div>
    );
  }
  if (bgColor == "highlight") {
    return (
      <div className='bg-info py-4'>
        <div dangerouslySetInnerHTML={{ __html: data }}></div>
      </div>
    );
  }
  return (
    <div className='pt-4'>
      <div dangerouslySetInnerHTML={{ __html: data }}></div>
    </div>
  );
};

export default RichtextSectionComponent;
