import React from "react";

const TowColTableComponent = (props) => {
  const data = props;

  if (!data?.items.length) return <></>;

  return (
    <>
      <div className='container table-responsive p-2'>
        <table className='table table-bordered'>
          <thead className='thead-dark'>
            <tr>
              <th scope='col'>Applications</th>
              <th scope='col'>Item Codes</th>
            </tr>
          </thead>
          <tbody>
            {data?.items.map((item, index) => (
              <tr key={index}>
                <th scope='row'>{item?.key}</th>
                <td>{item?.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TowColTableComponent;
