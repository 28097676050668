import Image from "next/image";
import Link from "next/link";

const ProductGalleryWithLinkComponent = ({
  heading,
  subHeading,
  imageBackgroundColor,
  items,
}) => {
  if (items === undefined) {
    return <></>;
  } else {
    return (
      <div className='product-catalog-section'>
        <h4 className='d-flex py-0 align-items-center gap-2'>
          <span>{heading}</span>
        </h4>
        <p>{subHeading}</p>
        <div className='row'>
          {items.length > 0 &&
            items.map((item, idx) => {
              const img = item.image.length > 0 ? item.image[0].url : "";
              const width =
                item.image.length > 0 ? item.image[0].metadata.pixelWidth : 300;
              const height =
                item.image.length > 0
                  ? item.image[0].metadata.pixelHeight
                  : 300;
              let bgCSS = "";
              if (imageBackgroundColor) {
                switch (imageBackgroundColor) {
                  case "gray":
                    bgCSS = "bg-light-gray";
                    break;
                  default:
                    break;
                }
              }

              return (
                <div key={idx} className='col-sm-3 mb-3'>
                  <div className='product-catalog'>
                    <div className={`product-image product-img ${bgCSS}`}>
                      <Image
                        src={img}
                        width={width}
                        height={height}
                        alt=''
                        className='img-fluid'
                        layout='raw'
                      ></Image>
                    </div>

                    <div className='product-body'>
                      <span className='product-caption'>
                        {item.url && (
                          <Link href={item.url} legacyBehavior>
                            <a>{item.title}</a>
                          </Link>
                        )}
                        {!item.url && <span>{item.title}</span>}
                      </span>
                    </div>
                  </div>
                  {item.notes && (
                    <div className='product-instructions'>{item.notes}</div>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    );
  }
};

export default ProductGalleryWithLinkComponent;
