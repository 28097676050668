import Image from "next/image";
import Link from "next/link";
import React from "react";

const TileListComponent = ({ heading, subHeading, items, alignment }) => {
  const cssMain = alignment ? (alignment == "center" ? "text-center" : "") : "";
  const cssRow = alignment
    ? alignment == "center"
      ? "row justify-content-center"
      : ""
    : "row";

  if (items === undefined) {
    return <></>;
  } else {
    const isAU = process.env.NEXT_PUBLIC_SITE_IS_AU === "true";
    return (
      <div className={cssMain}>
        {heading && <h3 className='card-title'>{heading}</h3>}
        {subHeading && <p>{subHeading}</p>}
        <div className={cssRow}>
          {items.map(function (item, idx) {
            const auLink = isAU && item?.link;
            const nzLink = !isAU && item?.nzUrlLink;
            return (
              <React.Fragment key={idx}>
                {auLink && (
                  <div className='col-6 col-sm-6 col-md-3 pb-3'>
                    <Link href={item?.link} legacyBehavior>
                      <a target='_blank' rel='noreferrer'>
                        <Image
                          alt='Image'
                          className='img-fluid border'
                          src={item?.image[0]?.url}
                          width={item?.image[0]?.metadata?.pixelWidth}
                          height={item?.image[0]?.metadata?.pixelHeight}
                          layout='raw'
                        ></Image>
                      </a>
                    </Link>
                  </div>
                )}{" "}
                {nzLink && (
                  <div className='col-6 col-sm-6 col-md-3 pb-3'>
                    <Link href={item?.nzUrlLink} legacyBehavior>
                      <a target='_blank' rel='noreferrer'>
                        <Image
                          alt='Image'
                          referrerPolicy='noreferrer'
                          className='img-fluid border'
                          src={item?.image[0]?.url}
                          width={item?.image[0]?.metadata?.pixelWidth}
                          height={item?.image[0]?.metadata?.pixelHeight}
                          layout='raw'
                        ></Image>
                      </a>
                    </Link>
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  }
};

export default TileListComponent;
