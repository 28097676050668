import styles from "./ImageBlockComponent.module.scss";

const PageImageBlockComponent = ({ caption, image }) => {
  if (image === undefined) {
    return <></>;
  } else {
    const img = image.length > 0 ? image[0].url : "";
    const imgCss = `url(${img}); height:385px`;
    const showCaption = caption ? true : false;

    return (
      <>
        <div
          className={`${styles.bannerSection} w-100`}
          style={{ backgroundImage: imgCss }}
        >
          {showCaption && (
            <div className={styles.polygonWt}>
              <div className='container p-2'>
                <div className='pt-4'>
                  <h2 className='h2 text-white fw-500'>{caption}</h2>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
};

export default PageImageBlockComponent;
