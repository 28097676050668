import Link from "next/link";
import React from "react";

const PcvTableComponent = ({ items = [] }) => {
  return (
    <div className='mb-4'>
      <div className='table-responsive'>
        <table className='table-bordered table'>
          <thead>
            <tr className='bg-primary text-white'>
              <th>
                <strong>Ryco No.</strong>
              </th>
              <th>
                <strong>OE Make</strong>
              </th>
              <th>
                <strong>OE Number</strong>
              </th>
              <th>
                <strong>Engine</strong>
              </th>
              <th>
                <strong>Date</strong>
              </th>
              <th>
                <strong>Main Applications</strong>
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length > 0 &&
              items.map((item, idx) => {
                return (
                  <tr key={idx}>
                    <td>
                      <Link href={`/part/${item.stockCode}`} legacyBehavior>
                        <a>{item.stockCode}</a>
                      </Link>
                    </td>
                    <td>{item.oEMake}</td>
                    <td>{item.oENumber}</td>
                    <td>{item.engine}</td>
                    <td>{item.date}</td>
                    <td>{item.mainApplications}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PcvTableComponent;
