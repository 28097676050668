import { useRouter } from "next/router";
import { useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const showAlert = (error) => {
  MySwal.fire({
    title: "Error Occured!",
    text: error,
    icon: "error",
    timerProgressBar: false,
    showConfirmButton: false,
    showCancelButton: true,
    cancelButtonText: "Close",
  });
};

const FindPartsSection = ({ mainTitle }) => {
  const isAU = process.env.NEXT_PUBLIC_SITE_IS_AU == "true";
  const apiEndPoint = process.env.SEARCH_API_URL;
  const country = isAU ? "AU" : "NZ";
  const defaultState = isAU ? "-" : "NZ";
  const router = useRouter();
  const [currentState, setCurrentState] = useState(defaultState);
  const [regoNumber, setRegoNumber] = useState("-");
  const [regoSearchState, setRegoSearchState] = useState(!isAU);
  const [vinSearchState, setVinSearchState] = useState(false);
  const [href, setHref] = useState("/search/-/-");

  const changeSate = (state) => {
    setCurrentState(state);
    setRegoSearchState(state);
  };

  const handleRegoNoChange = (event) => {
    setRegoNumber(event.target.value);
  };

  const handleClick = (e) => {
    e.preventDefault();
    let newHref = "";
    if (regoSearchState) {
      newHref = "/search/" + currentState + "/" + regoNumber;
    }
    if (vinSearchState) {
      newHref = "/search/vin/" + vinNumber;
    }

    if (newHref !== "") {
      setHref(newHref);
      router.push(newHref);
    } else {
      showAlert("Please enter rego");
    }
  };

  return (
    <div className='clearfix'>
      <div className='row g-0'>
        <div className='col-lg-6'>
          <div
            className='hand-cover-Image w-100 h-100'
            style={{
              background: "url(/img/hand.webp) no-repeat center center",
              backgroundSize: "cover",
            }}
          ></div>
        </div>
        <div className='col-lg-6 bg-secondary text-white p-md-5 p-3 search-form'>
          <h3 className='h4'>{mainTitle}</h3>
          <small>Enter your rego number to find parts.</small>
          <div className='row g-2 mt-2'>
            <div className='col'>
              <input
                type='text'
                className='form-control'
                placeholder='REGO No.'
                id='regoNo'
                name='regoNo'
                disabled={vinSearchState}
                onChange={handleRegoNoChange}
              />
            </div>
            <div className='col'>
              {country == "AU" && (
                <select
                  className='form-select'
                  required=''
                  name='regoStateAU'
                  id='regoStateAU'
                  onChange={(event) => changeSate(event.target.value)}
                  value={currentState}
                  disabled={vinSearchState}
                >
                  <option>Select State</option>
                  <option value='NSW'>NSW</option>
                  <option value='ACT'>ACT</option>
                  <option value='VIC'>VIC</option>
                  <option value='SA'>SA</option>
                  <option value='WA'>WA</option>
                  <option value='NT'>NT</option>
                  <option value='QLD'>QLD</option>
                  <option value='TAS'>TAS</option>
                  <option value='NHV'>National Heavy Vehicle</option>
                </select>
              )}
              {country == "NZ" && (
                <select
                  className='form-select'
                  required=''
                  name='regoStateAU'
                  id='regoStateAU'
                  onChange={(event) => changeSate(event.target.value)}
                  value={currentState}
                  disabled={vinSearchState}
                >
                  <option value='NZ'>NZ</option>
                </select>
              )}
            </div>
            <div className='col'>
              <a
                className='btn btn-primary mb-2 w-100'
                href={href}
                onClick={handleClick}
              >
                Search
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindPartsSection;
