/* eslint-disable @next/next/no-img-element */
import Image from "next/image";
import React from "react";

const TextTilesComponent = ({ title, subTitle, bannerImage, items, backgroundColor }) => {
  const img = bannerImage?.length > 0 ? bannerImage[0].url : null;
  backgroundColor = backgroundColor ?? "Default";
  const mainBackgroundColorCss = backgroundColor === "White" ? "" : " paintRycoTeal text-white";
  const mainTextColor = backgroundColor === "White" ? "" : "text-white";
  return (
    <div className={"aboutUsSubSection py-5 " + mainBackgroundColorCss}>
      <div className='container-fluid px-0'>
        <h2 className='h3'>{title}</h2>
        {img && (
          <Image
            src={img}
            alt='banner'
            width='1920'
            height='560'
            className='img-fluid'
            layout='raw'
          />
        )}
        <h3 className={"subheaderblock mt-3 mb-3 text-center " + mainTextColor}>
          {subTitle}
        </h3>
      </div>
      <div className={"container " + mainTextColor}>
        <div className='row'>
          {items?.length > 0 &&
            items.map(function (item, idx) {
              const icon = item.icon?.length > 0 ? item.icon[0].url : null;
              return (
                <div className='col-sm-6 col-lg-4' key={idx}>
                  {icon && (
                    <img
                      src={icon}
                      alt=''
                      width='66'
                      height='66'
                      className='aboutusicon'
                      loading='lazy'
                    />
                  )}
                  <br />
                  <p>
                    <strong>{item.title}</strong>
                  </p>
                  <p>{item.subTitle}</p>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default TextTilesComponent;
